import DefaultLayout from "components/templates/DefaultLayout";
import { graphql, StaticQuery } from "gatsby";
import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import HtmlParser from "react-html-parser";

import cn from "utils/cn";

import * as styles from "./PrivacyPolicy.module.scss";

interface IPrivacyPolicy extends React.ComponentProps<"div"> {}
type TQueryData = {
  wpPage: {
    content: string;
  };
};

const PrivacyPolicy: React.FC<IPrivacyPolicy> = ({ className, ...props }) => {
  return (
    <StaticQuery
      query={graphql`
        query MyQuery {
          wpPage(title: { eq: "Polityka prywatności" }) {
            content
          }
        }
      `}
      render={({ wpPage: { content } }: TQueryData) => {
        return (
          <DefaultLayout>
            <section className={styles.element}>
              <div className="container">
                <div className={styles.content}>{HtmlParser(content)}</div>
              </div>
            </section>
          </DefaultLayout>
        );
      }}
    />
  );
};

export default PrivacyPolicy;
